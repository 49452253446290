import React from 'react';
import styled from 'styled-components';
import { css } from 'emotion';

/* global tw */


class CoronaDisplay extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }    

    render() {
        return(
            <div className={css`${tw``}`}>
            <center>
            <img 
            className={css`${tw`bg-cover lg:pt-24 pt-20 w-screen lg:h-screen h-full`}`} 
            src={require('../../assets/img/landing-page-banner-2.png')} 
            alt="health advisory" 
            // height="600" 
            />
            </center>
            </div>

        )
    }

}

export default CoronaDisplay;