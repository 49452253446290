import React from 'react';
import {css} from 'emotion';

import Slider from "react-slick";

import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

const styles = {
    wrapper: css`
        width: 100vw;
        @media(min-width: 1000px){
            width: 80vw;

        }
        @media(max-width: 600px){
           display: none;

        }
        height: 4rem;
        position: relative;
        align-items: center;
        z-index: 0;
        transform: translateY(-5vh) translateZ(0px);
        perspective: 3000px;
        &:after{
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5vw;
            background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255, 0));
            z-index: 10;
        }
        &:before{
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5vw;
            background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255, 0));
            z-index: 10;
        }
    `,
    partnerLogo: css`
    width: 10vw;
    `,
    slide: css`
    `,
}
class PartnersSlider extends React.Component {
    
    render(){
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear",
            responsive: [
                {   
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,                
                    }
                },
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        };

        return(
            <div className={styles.wrapper}>
                <Slider {...settings}>
                   
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/HONDA CITY.png')} alt="Zyppys"/>
                    </div>
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Maruthi-Ritz.png')} alt="Zyppys"/>
                    </div>
                    {/* <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/partners/uber-logo.svg')} alt="Zyppys" />
                    </div> */}
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Maruthi Suzuki Ciaz.png')} alt="Zyppys"/>
                    </div>
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Toyota-Etios-liva.png')} alt="Zyppys"  />
                    </div>
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Mini.png')} alt="Zyppys"/>
                    </div>
                    {/* <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/partners/eco-logo.svg')} alt="Zyppys" />
                    </div> */}
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Maruthi-Suzuki-Wagon-R.png')} alt="Zyppys" />
                    </div>
                    <div className={styles.slide}>
                        <img className={styles.partnerLogo} src={require('../assets/img/cars/Hyundai Grand I10.png')} alt="Zyppys" />
                    </div>
                    
                </Slider>
            </div>
        );
    }
}

export default PartnersSlider;