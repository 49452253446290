import React from 'react';
import styled from 'styled-components';
import {css} from 'emotion';
import Row from './Row';
import Swiper from 'react-id-swiper';
import '../../node_modules/react-id-swiper/src/styles/css/swiper.css';

import adarsh from '../assets/img/adarsh.png'
import praneesha from '../assets/img/praneesha.png'
import ravi from '../assets/img/ravi.png'

/* global tw */

const Section = styled.section`
    ${tw`flex flex-wrap w-screen h-auto py-16 bg-grey-light items-center relative z-0`};
    min-height: 80.9vh;
`

const ParallaxImg = styled.img`
    ${tw`absolute z-10 opacity-10`};
    right: -50%;

`
const SectionHeading = styled.h2`
    ${tw`font-bold tracking-tight mb-4 ml-2 w-full`}
    font-size: ${1.618 + 0.809}rem;
    margin-top: 0px;
`
const TestimonalWrapper = styled.div`
    ${tw`flex flex-wrap items-center rounded-lg bg-white px-6 mx-auto lg:mx-0 my-2 w-full lg:w-1/4`};
    min-height: 15rem;
    height: auto;
    // width: 20rem;
    box-shadow: 0 30px 34px 0 rgba(0,0,0,0.10);
    p{
        ${tw`py-12 text-sm lg:text-normal`}
        opacity: 0.77;
        font-family: 'Poppins';
        line-height: 1.618;
        color: #393D41;
    }
    &:nth-of-type(even){
        @media(min-width: 992px){
            margin-top: 5rem;
        }
    }
`
const User  = styled.div`
    ${tw`flex items-center w-full py-8`};
    border-top: 1px solid rgba(0,0,0,0.1);
    p{
        ${tw`my-0 text-grey-dark py-0`};
        line-height: 1.2;
    }

`

const UserPic = styled.div`
    ${tw`relative rounded-full overflow-hidden mr-2 lg:mr-4`};
    height: 3rem;
    width: 3rem;
    background-image: ${props => `url(${props.image})`};
    background-size: cover;
    background-repeat: no-repeat;

`
const UserName = styled.p`
    ${tw`my-1 font-medium text-2xl text-grey py-0`};
    line-height: 1;
`
const UserCity = styled.p`
    ${tw`my-0 text-grey text-sm opacity-60 py-0`};
    line-height: 1;
`

class SectionFive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: [
                {
                    text: "Zyppys saves me more time and money every time I travel in a rented car. This is such a life saver :)",
                    name: "Adarsh",
                    image: adarsh,
                    city: 'Bengaluru'
                },
                {
                    text: "To be able to know for sure that you are getting the best deal out of everything and you don't have to look back again is priceless!",
                    name: "Praneesha",
                    image: praneesha,
                    city: 'Hyderabad'
                },
                {
                    text: "Zyppys makes it so easy to rent a car. I also love the reward points. I've always been able to find a great deal for all three of my last car rentals!",
                    name: "Ravi",
                    image: ravi,
                    city: 'Chennai'
                },
            ],
        }
    }
    render() {
        const params = {
            slidesPerView: 'auto',
            spaceBetween: 40,
            // containerClass: 'testimonial-swiper-container',	
            // wrapperClass: 'testimonal-swiper-wrapper',
            slideClass: 'testimonal-swiper-slide',
        }
        return (
            <Section>
                <Row justifyBetween className={css`${tw`relative z-50`}`}>
                    <SectionHeading>What People Say?</SectionHeading>
                    {this.state.testimonials.map((obj, key) => 
                        <TestimonalWrapper>
                            <p>{obj.text}</p>
                            <User>

                                <UserPic image={obj.image}/>
                                <div>
                                    <UserName>{obj.name}</UserName>
                                    <UserCity>{obj.city}</UserCity>
                                </div>
                            </User>
                        </TestimonalWrapper>               
                    )}
                </Row>
                <ParallaxImg src={require('../assets/img/bg-shape-icon.svg')}/>
            </Section>
        );
    }
}

export default SectionFive;


// const UserPic = (props) => (

//     <TestimonalPic style={{ backgroundImage: `url(${props.bgImage})` }}>

//     </TestimonalPic>
// );