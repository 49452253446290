import React from 'react';
import styled from 'styled-components';
import {css} from 'emotion';
import Row from './Row';
import Swiper from 'react-id-swiper';
import '../../node_modules/react-id-swiper/src/styles/css/swiper.css';

/* global tw */

const Section = styled.section`
    ${tw`flex flex-wrap w-screen bg-grey-light`};
    min-height: 80.9vh;
    padding-top: 4rem;

`
const SectionHeading = styled.h2`
    ${tw`font-bold tracking-tight mb-4 ml-2 w-full lg:pr-12 mt-4 lg:mt-16 mb-0`}
    font-size: 2.427rem;
    @media (min-width: 992px){
        font-size: ${1.618 * 2}rem;
    }
    line-height: 1.1;
`
const USP = styled.div`
    ${tw`w-full lg:w-1/3 lg:px-6 my-2 lg:my-8`};
    p{
        ${tw`font-medium`};
        opacity: 0.77;
        font-size: 1.26rem;
        color: #000000;
        letter-spacing: -0.4px; 
    }

`
const Number = styled.h4`
    ${tw`font-bold my-0`};
    font-size: 1rem;
    color: #66686A;
    letter-spacing: -1.29px;
    // border-bottom: 2px solid #CA43D1;
    ::after{
        ${tw`mb-4 opacity-50`};
        content: "";
        display: block;
        height: 2px;
        background: #CA43D1;
        width: 2rem;
        @media (min-width: 992px){
            width: 61.8%;
        }
    }
   
    @media (min-width: 992px){
        font-size: ${1.618 * 2}rem;
    }
`

const SectionFive = (props) => (
    <Section>

        <Row justifyBetween className={css`${tw`relative items-start z-50`}`}>
            <div className={css`${tw`w-full lg:w-1/4`}`}>
                <SectionHeading>
                    Why should you use Zyppys?
                </SectionHeading>
            </div>
            <div className={css`${tw`w-full lg:w-2/3 pt-8`}`}>
                <Row>
                    <USP>
                        <Number>
                            01
                        </Number>
                        <p>
                            Less Searching,<br/>More Saving.
                        </p>
                    </USP>
                    <USP>
                        <Number>
                            02
                        </Number>
                        <p>
                            {/* Lowest Price guaranteed across Internet. If not, let us know. We'll match it. */}
                            Lowest Price guaranteed across Internet.
                        </p>
                    </USP>
                    <USP>
                        <Number>
                            03
                        </Number>
                        <p>
                            24/7 Tele support.
                        </p>
                    </USP>
                    <USP>
                        <Number>
                            04
                        </Number>
                        <p>
                            Seamless booking.
                        </p>
                    </USP>
                    {/* <USP>
                        <Number>
                            05
                        </Number>
                        <p>
                            Free Cancellation.
                        </p>
                    </USP> */}
                </Row>
            </div>


        </Row>
    </Section>
);


export default SectionFive;

