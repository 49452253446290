import React from 'react';
import {css} from 'emotion';
import {Spring} from 'react-spring/renderprops';

/*global tw*/
// class PopupSlider extends React.Component{
//     render(){
//         return(
//             <Spring from={{opacity: 0, transform: 'translateY(30vh)'}}
//                 to={{ opacity: this.props.active? 1 : 0, translate: this.props.active ? 'translateY(0)' : 'translateY(30vh)'}}>
//                 {value =>
                
//                     <div className={css`${tw`fixed z-600 h-24 w-24 bg-white`}`} style={value}>
//                         <p>Yo!</p>
//                     </div>
//                 }
//             </Spring>
//         )
//     }
// }

const PopupSlider = (props) => (
    <Spring from={{ opacity: 0, transform: 'translateY(30vh)' }}
        to={{ opacity: props.active ? 1 : 0, translate: props.active ? 'translateY(0)' : 'translateY(30vh)' }}>
        {value =>
            <div className={css`${tw`fixed pin-x z-999 min-h-24 min-w-24 bg-white`}; top:25%`} style={value}>
                {props.children}
            </div>
        }
    </Spring>
);
export default PopupSlider;