import React from 'react';
import styled, {css} from 'styled-components';
import Row from './Row.js'
import Swiper from 'react-id-swiper';
import "../../node_modules/react-id-swiper/src/styles/css/swiper.css";
import './custom-swiper.css';

import fancyCurvesDown from '../assets/img/fancy-curves-divider-down.svg';


/* global tw */
const Section = styled.section`
    ${tw`w-screen lg:h-screen bg-black relative z-0 mt-0 py-8 lg:py-12`};
    height: 50rem;
    ::after{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 10rem;
        background-image: url(${fancyCurvesDown});
        background-repeat: no-repeat;
        background-size: cover;
        bottom: -10rem;
    }
`
const Heading = styled.h2`
    ${tw`font-medium text-grey m-0`};
    span {
        ${tw`block text-white font-black`};
    }
    @media (min-width: 992px){
        font-size: ${1.618 + 0.809}rem;
    }
`

const SwiperSlide = styled.div`
    ${tw`bg-white flex flex-wrap items-center relative px-8 py-12`};
    :hover{
        // transform: scale(1.2);
    }
`

class SectionTwo extends React.Component{
    
    constructor(props){
        super(props);
        this.swiper = React.createRef();
    }

    render(){
        const swiperOptions = {
            // height: 150,
            // width: 150,
            spaceBetween: 100,
            slidesPerView: 'auto',
            setWrapperSize: true,
            // centeredSlides: true,
            // containerClass: 'swiper-container-one',
            // slideClass: 'swiper-slide-one',
            // wrapperClass: 'swiper-wrapper-one',
            observer: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                // when window width is <= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                // when window width is <= 480px
                480: {
                    slidesPerView: 1,
                    // spaceBetween: 30
                },
                // when window width is <= 640px
                640: {
                    slidesPerView: 2,
                    // spaceBetween: 40
                }
            }
        }
        return(
            <Section>
                <Row>
                    <Heading> How about these <span>Popular destinations?</span></Heading>
                </Row>
                {/* <Row> */}
                    <Swiper {...swiperOptions} ref={this.swiper}>
                        <SwiperSlide>
                            1
                        </SwiperSlide>
                                <SwiperSlide>
                                    2
                        </SwiperSlide>
                                <SwiperSlide>
                                    3
                        </SwiperSlide>
                                <SwiperSlide>
                                    4
                        </SwiperSlide>
                                <SwiperSlide>
                                    5
                        </SwiperSlide>
                    </Swiper>
                {/* </Row> */}
            </Section>
        );
    }
} 
  


export default SectionTwo