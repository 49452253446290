import React from "react";
import styled from "styled-components";


const AirportTransferIcon= (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" 
        height={props.height}
        viewBox="0 0 250 150"
        opacity={props.opacity} 
        className={props.className}>
        <path 
            fill={props.dark ? "#000" : "rgba(255,255,255,1)"}
            d="M225 70.7h-40l-41.7-44.1c-1.1-1.1-2.5-1.7-4.1-1.7h-60c-33.1 0-60 26.9-60 60s26.9 60 60 60c16.1 0 30.7-6.3 41.5-16.7h34.8c2.4 5.8 6.6 10.7 12.1 13.8 1.1.6 2.2 1.2 3.3 1.7 1 .4 2 .8 3.1 1.1.1 0 .2.1.3.1 1.8.5 3.8.8 5.7.9.6 0 1.1.1 1.7.1.6 0 1.1 0 1.7-.1.6 0 1.1-.1 1.7-.1 1.4-.2 2.7-.4 4-.8 8.6-2.3 15.5-8.5 18.8-16.7H225c3.2 0 5.8-2.6 5.8-5.8V76.5c0-3.2-2.6-5.8-5.8-5.8zm-102.4 35.2c-.7 1.4-1.4 2.7-2.2 4-2.2 3.5-4.7 6.6-7.7 9.4-5.9 5.7-13.3 9.9-21.5 12-3.9 1-7.9 1.5-12.1 1.5-8 0-15.6-1.9-22.3-5.4-8.9-4.6-16.2-11.9-20.8-20.7l-1.2-2.4c-2.8-6.1-4.3-12.9-4.3-20 0-26.8 21.7-48.5 48.5-48.5 10.7 0 20.6 3.5 28.6 9.4 12.1 8.8 19.9 23.1 19.9 39.2.2 7.7-1.6 15-4.9 21.5zm76.6 15.7c0 .1 0 .1 0 0-.3 1.3-.7 2.4-1.3 3.6-.5 1-1.1 2-1.8 3-2.6 3.4-6.5 5.9-11 6.6-.9.2-1.9.2-2.8.2-4.3 0-8.3-1.6-11.3-4.2-.9-.8-1.8-1.7-2.5-2.7-.3-.4-.6-.7-.8-1.1-.4-.6-.7-1.2-1-1.8-1.2-2.3-1.8-5-1.8-7.7v-1c.1-2 .6-3.9 1.3-5.7 2.6-6.4 8.9-10.8 16.2-10.8 1 0 1.9.1 2.8.2 6.1 1 11.1 5.1 13.3 10.6.7 1.8 1.2 3.7 1.3 5.7v1c-.1 1.4-.3 2.8-.6 4.1zm20-5.1H210c-.1-2-.3-3.9-.8-5.7-1-4-2.8-7.7-5.3-10.8-4.5-5.8-11.2-9.7-18.9-10.6-1.1-.1-2.2-.2-3.3-.2-13.4 0-24.5 9.2-27.5 21.6-.5 1.8-.7 3.7-.8 5.7h-23.2c5.7-9.2 9-20 9-31.7 0-19.9-9.7-37.5-24.6-48.4l22.1.1 2.4 2.5 31.8 31.7.3.3 9.3 9.3c1.1 1.2 2.6 2 4.4 2h34.2v34.2z"/>
        <path fill="#5500DB" d="M43.6 83.4l11.9 14.1.4.4c.3.6.7 1.1 1.2 1.5 2.1 2.2 5.4 3 8.4 1.8l43.8-17.5c4-1.6 5.9-6.1 4.3-10.1-1.6-4-6.1-5.9-10.1-4.3l-13.2 5.3L62.6 65c-.9-.3-1.9-.3-2.8.1l-2.4.9c-1.1.4-1.5 1.8-.7 2.8L68.9 83l-4.4 1.8-15.6-5.4c-.4-.1-.8-.1-1.2 0l-3.4 1.4c-1.1.3-1.5 1.7-.7 2.6zm61.5-10.2c1.7-.7 3.7.2 4.4 1.9.7 1.7-.2 3.7-1.9 4.4-1.7.7-3.7-.2-4.4-1.9-.7-1.7.2-3.7 1.9-4.4z"/>
        <path fill="#CB53D1" d="M48.9 79.3c-.4-.1-.8-.1-1.2 0l-3.4 1.4c-1.1.4-1.4 1.8-.7 2.7l6.1 7.3 14.8-6-15.6-5.4z"/>
        <path fill="none" d="M49.6 90.7h.1l14.8-6"/>
    </svg>
);

export default AirportTransferIcon;