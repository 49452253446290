import React from 'react';
import {css} from 'emotion';
import styled from 'styled-components';
import OutstationCabIcon from '../SvgComponents/icons/OutstationCabIcon.js';
import SelfDrivenIcon from '../SvgComponents/icons/SelfDrivenIcon.js';
import AirportTransferIcon from '../SvgComponents/icons/AirportTransferIcon.js';
import HourlyRentalIcon from '../SvgComponents/icons/HourlyRentalIcon.js';
/*global tw */

const Wrapper = styled.div`
    ${tw`   w-auto 
            flex-no-wrap 
            whitespace-no-wrap 
            px-4 
            py-4
            lg:py-2 
            font-medium 
            lg:rounded-full 
            cursor-pointer 
            select-none
            flex 
            items-center
            overflow-hidden
            lg:w-auto
            w-full
            justify-between
    `};
    border-radius: 16px 16px 0 0;
    ${props => !props.active && tw`hidden lg:flex`};
    color: ${props => props.active ? 'black' : 'white'};
    :hover{
        background: rgba(255, 255, 255, 0.54);
        color: black;
        transition: all .2s ease-in-out;
    }
    ${'' /* background: ${props => props.active ? 'rgba(255,255,255,1)' : 'transparent'}; */}
    background: ${props => props.active ? '#F0F2F5' : 'transparent'};
    @media (min-width: 992px){
        background: ${props => props.active ? '#F0F2F5' : 'transparent'};
    }
`
const RentingChoiceTab = (props) => (
    <Wrapper active={props.active} onClick={props.onClick}>
        <div className={css`${tw`inline-flex items-center`}`}>
            {props.type === 'OS' && 
                <OutstationCabIcon dark={props.active} 
                    height="24" 
                    opacity= {props.active? 1 : .65}
                    className={css`${tw`-mt-1 mr-4`}`}/>
            }
            {props.type === 'SD' && 
                <SelfDrivenIcon dark={props.active} 
                    height="24" 
                    opacity= {props.active? 1 : .65}
                    className={css`${tw`-mt-1 mr-4`}`}/>
            }
            {props.type === 'AT' && 
                <AirportTransferIcon dark={props.active} 
                    height="24" 
                    opacity= {props.active? 1 : .65}
                    className={css`${tw`-mt-1 mr-4`}`}/>
            }
            {props.type === 'HR' && 
                <HourlyRentalIcon dark={props.active} 
                    height="24" 
                    opacity= {props.active? 1 : .65}
                    className={css`${tw`-mt-1 mr-4`}`}/>
            }
            <p className={css`${tw`lg:mt-1 font-bold text-lg tracking-halfpx lg:text-normal`}`}>{props.children}</p>
        </div>
        <img className={css`${tw`lg:hidden inline-flex h-2 mx-4`}`} 
             src={require('../SvgComponents/angle-down.svg')}/>
    </Wrapper>
);

export default RentingChoiceTab;


