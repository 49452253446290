import React from 'react';
import {css} from 'emotion';
import styled from 'styled-components';
import Row from './Row';
import "../../node_modules/react-id-swiper/src/styles/css/swiper.css";

/* global tw */

const Section = styled.section`
    ${tw`w-full relative mx-auto z-0 justify-start pb-18`};
    min-height: 40rem;
    height: auto;
`
const SectionHeading = styled.h2`
    ${tw`font-black mb-2`};
    font-size: ${2*1.618}rem;
    line-height: 1;
    letter-spacing: -0.1rem;
`
const Paragraph = styled.p`
    ${tw`font-poppins`};
    opacity: 0.77;
    color: #66686A;
    letter-spacing: 0.01rem;
    line-height: 1.618;
`
const SmLink = styled.a`
    ${tw`flex items-center w-full lg:w-1/2 my-1 lg:my-6 flex items-center no-underline text-grey-dark`}
    img{
        ${tw`mr-4`};
    }
`
const noLikey = styled(SmLink)`
    ${tw`opacity-50`}
`

class SectionSix extends React.Component{
    constructor(props){
        super(props);
    }
    componentDidMount() {
        this.setState({localStorage:JSON.parse(localStorage.getItem('vendorDetails'))})
        window.onresize = function(){
            console.log('onResize called', 'font-size: 20px, color: blue');
        }
    }
    render(){
        return(
            <Section>
                <Row>
                    <div className={css`${tw`w-full lg:w-1/3`}`}>
                        <SectionHeading>
                            If you're a<br/>Travel Buff,
                        </SectionHeading> 
                        <Paragraph>
                            You need to follow us. We’re soon coming up with amazing travel content made specifically for non-stereotypical India!
                        </Paragraph>
                        <div className={css`${tw`flex flex-wrap w-full mt-4`}`}>
                            <SmLink href="https://www.facebook.com/zyppys/" target="_blank">
                                <img src={require('../assets/img/facebook.svg')}/>
                                Facebook
                            </SmLink>
                           
                            <SmLink
                                href="https://www.instagram.com/zyppyscarrental" 
                                target="_blank">
                                <img src={require('../assets/img/instagram.svg')}/>
                                Instagram
                            </SmLink>
                            <SmLink 
                                href="https://www.twitter.com/zyppys" 
                                target="_blank">
                                <img src={require('../assets/img/twitter.svg')}/>
                                Twitter
                            </SmLink>
                            <SmLink 
                                href="#menolikey">
                                <img src={require('../assets/img/sadSmiley.svg')}/>
                                Nah, I don't like you
                            </SmLink>
                        </div>
                    </div>
                </Row>
                <img className={css`${tw`absolute pin-r pin-b z-back`}; height: 90%;`} src={require('../assets/img/illustrations/grey-mountains.svg')} alt="Mountains" />
            </Section>

        );
    }
}
    
    
export default SectionSix;